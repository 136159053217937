<template>
    <div class="container">
        <div class="font-weight-bold">FollowUp</div>
          <div class="row mt-5">
         <div class="col-md-12 col-12 text-center">
            <div class="row  border rounded report d-flex justify-content-between p-2 p-md-4">
                
                <div class="col-md-6 col-12 pr-md-4 p-0">
                    <h4 class="ju header4">Follow Up</h4>
                    <p class="ju paragraph1">How many Members are you gathering? Think about fast growing church and maintaining your members information and reaching out to them.</p>


                </div>
                <div class="col-md-5 col-12 border-bottom">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">Expense Report</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>
                
                <div class="col-md-6 col-12 pr-md-4 p-0">
                </div>
                <div class="col-md-5 col-12 border-bottom mt-4">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">Income Inflow Report</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>

                <div class="col-md-6 col-12 pr-md-4 p-0">
                </div>
                <div class="col-md-5 col-12 mt-4">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">Offering Contribution Report</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>
                 <div class="col-md-6 col-12 pr-md-4 p-0">
                </div>
                <div class="col-md-5 col-12 mt-4">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">First Timer Report</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>
            </div>
     </div>
    </div>

       
        <!--border 2-->
        <div class="row mt-5">
         <div class="col-md-12 col-12 text-center">
            <div class="row  border rounded report d-flex justify-content-between p-2 p-md-4">
                
                <div class="col-md-6 col-12 pr-md-4 p-0">
                    <h4 class="ju header4">Statement of Activities</h4>
                    <p class="ju paragraph1">How much profit are you making? Are your assets growing faster than your liabilities? is cash flowing or getting stuck</p>


                </div>
                <div class="col-md-5 col-12">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">Income Statement</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>
            </div>
     </div>
    </div>
        <!--border 2-->
        <!--border 3-->
           <div class="row mt-5 mb-5">
         <div class="col-md-12 col-12 text-center">
            <div class="row  border rounded report d-flex justify-content-between p-2 p-md-4">
                
                <div class="col-md-6 col-12 pr-md-4 p-0">
                    <h4 class="ju header4">Transactions</h4>
                    <p class="ju paragraph1">How much profit are you making? Are your assets growing faster than your liabilities? is cash flowing or getting stuck</p>


                </div>
                <div class="col-md-5 col-12 border-bottom">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">Account Transactions</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>
                <div class="col-md-6 col-12 pr-md-4 p-0">
                </div>
                <div class="col-md-5 col-12 mt-4">
                    <div class="row d-md-flex justify-content-md-between d-flex justify-content-between">
                        <h4 class="ju header4 d-md-flex justify-content-md-start" style="color: #136acd">Account Activites and Balnace</h4>
                        <span><i class="pi pi-angle-right" style="font-size: 30px; color: #136acd"></i></span>
                    </div>
                    <div class="row">
                        <p class="ju mb-3 paragraph1">Snapshot of what your business owns or is due to receive from others (assets). what it owes to others (liabilities). and what you</p>
                    </div>
                </div>
            </div>
            
     </div>
    </div>
        <!--border 3-->
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.report{
    width: 100%;
    margin: auto;
}
.ju{
    text-align: start;

}
.header4{
    font-size: 20px;
    font-weight: 700;
}
.paragraph1{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;

}
.rounded{
    border-color:  #b0b2b5!important;
    border-radius: 15px!important;

}

</style>